const initialState = {
  inited: false,
  formConfig: null,
  id: null,
  formConfigUrl: "service_desc/ticket/get-form?form_code=ticket_edit",
  formRef: null,
  formTempValues: null,
  editDisabled: null,
  comments: [],
  status: null,
  isResponsinble: false,
  isPhotoRequired: null,
  coords: { latitude: null, longitude: null },
  history: [],
};

export function ticket(state = Object.assign({}, initialState), action) {
  switch (action.type) {
    case "TICKET_RESET_STATE": {
      return Object.assign({}, initialState);
    }
    case "TICKET_SET_INITED":
      return Object.assign({}, state, {
        inited: action.inited,
      });
    case "TICKET_SET_FORM_CONFIG":
      return Object.assign({}, state, {
        formConfig: action.formConfig,
      });
    case "TICKET_SET_FORM_REF":
      return Object.assign({}, state, {
        formRef: action.formRef,
      });
    case "TICKET_SET_EDIT_DISABLED":
      return Object.assign({}, state, {
        editDisabled: action.editDisabled,
      });
    case "TICKET_SET_FORM_TEMP_VALUES":
      return Object.assign({}, state, {
        formTempValues: action.formTempValues,
      });
    case "TICKET_SET_COMMENTS":
      return Object.assign({}, state, {
        comments: action.comments,
      });
    case "TICKET_SET_HISTORY":
      return Object.assign({}, state, {
        history: action.history,
      });
    case "TICKET_SET_STATUS":
      return Object.assign({}, state, {
        status: action.status,
      });
    case "TICKET_SET_COORDS":
      return Object.assign({}, state, {
        coords: action.coords,
      });
    case "TICKET_SET_RESPONSIBLE":
      return Object.assign({}, state, {
        isResponsinble: action.responsible,
      });
    case "TICKET_SET_PHOTOREQUIRED":
      return Object.assign({}, state, {
        isPhotoRequired: action.photoRequired,
      });
    default:
      return state;
  }
}
