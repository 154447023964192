import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Box, Chip, Dialog, DialogContent } from '@material-ui/core'

const styles = (theme) => ({
  card: {
    minWidth: 275,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  author: {
    fontSize: 16,
    marginBottom: theme.spacing(),
  },
  pos: {
    marginBottom: 12,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '15px',
  },
  lastInspection: {
    background: 'rgba(255, 239, 241, 1)',
  },
  role: {
    background: 'rgba(22, 14, 240, 0.71)',
    color: 'white',
  },
  tag: {
    background: 'rgb(0, 176, 80)',
    color: 'white',
    textTransform: 'capitalize',
  },
  tagInspection: {
    background: 'rgb(255, 0, 0)',
  },
  imageBox: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
  },
  fullScreenImage: {
    maxWidth: '100%',
    maxHeight: '80vh',
    objectFit: 'contain',
    margin: '0 auto', 
  },
  dialogPaper: {
    padding: theme.spacing(2), 
    display: 'flex',
    justifyContent: 'center',  
    alignItems: 'center',
    maxWidth: '80vw', 
    maxHeight: '90vh', 
    width: 'unset'
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: 'white',
  },
})

class Comments extends React.Component {
  state = {
    selectedImage: null,
  }

  handleImageClick = (image) => {
    this.setState({ selectedImage: image })
  }

  handleClosePopup = () => {
    this.setState({ selectedImage: null })
  }

  render() {
    let { classes, comments, client } = this.props
    const { selectedImage } = this.state
    const lastInspectionID = comments.find((com) => com.tags.find((tag) => tag?.code === 'rework'))?.obj_id

    return (
      <React.Fragment>
        {comments.map((comment) => (
          <Card
            id={`ticket-comment-${comment['obj_id']}`}
            className={classes.card + ' ticket-comment'}
            key={comment['obj_id']}>
            <CardContent className={`${lastInspectionID === comment.obj_id && classes.lastInspection}`}>
              <Box className={classes.title}>
                <Typography className={classes.date} color="textSecondary" gutterBottom>
                  {comment.created}
                </Typography>
                {!!comment?.roles?.length &&
                  comment?.roles.map((role, index) => (
                    <Chip label={role} size="small" className={classes.role} key={role + index} />
                  ))}
                {!!comment?.tags?.length &&
                  comment?.tags.map((tag) => (
                    <Chip
                      label={tag?.name}
                      size="small"
                      key={tag.code}
                      className={`${classes.tag} ${tag?.code === 'rework' ? classes.tagInspection : ''}`}
                    />
                  ))}
              </Box>
              <Typography className={classes.author} variant="h5" component="h5">
                {comment.author}
              </Typography>
              <Typography variant="body1" component="p">
                {comment.comment}
              </Typography>
              {!!comment.photo.length && (
                <Box className={classes.imageBox}>
                  {comment.photo.map((guid) => (
                    <img
                      key={guid}
                      style={{
                        height: '200px',
                        width: '20%',
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                      src={`${client.host}/service_desc/file-storage/img-from-file?project=SERVICEDESC&token=${client.authToken}&guid=${guid}`}
                      alt={guid}
                      onClick={() => this.handleImageClick(guid)} // Открыть попап при клике
                    />
                  ))}
                </Box>
              )}
            </CardContent>
          </Card>
        ))}

        {selectedImage && (
          <Dialog
            open={true}
            onClose={this.handleClosePopup}
            maxWidth="md"
            fullWidth
            classes={{
              paper: classes.dialogPaper, 
            }}
          >
            <DialogContent>
              <img
                src={`${client.host}/service_desc/file-storage/img-from-file?project=SERVICEDESC&token=${client.authToken}&guid=${selectedImage}`}
                alt={selectedImage}
                className={classes.fullScreenImage}
              />
            </DialogContent>
          </Dialog>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    comments: state.ticket.comments,
    client: state.page.elkUser.restClient,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Comments))
