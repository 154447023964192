import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Crud from "@ksbteam/core/components/Crud";
import { connect } from "react-redux";
import { ticketsInit, ticketsSetCrudRef, ticketsOpenTicket, ticketsSetTab, ticketsMapSetTickets, ticketsMapSetInited } from "../../actions/tickets";
import Ticket from "../Ticket";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Filters from "./Filters";
import moment from "moment";
import { DATAGRID_DEFAULT_PROPS, TICKET_COLORS } from "../../constants/tickets";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Map from "./Map";
import CreateButton from "./CreateButton";
import ExportButton from "../ExportButton/ExportButton";
import { getRows } from "../helpers/helpers";
import { Box } from "@material-ui/core";

const styles = (theme) => ({
  root: {},
  progressWrapper: {},
  gridRoot: {
    "&.card-grid": {
      minWidth: "auto",
      width: "100%",
    },
    ".mobile &.card-grid .react-grid-Header": {
      display: "none",
    },
    ".mobile & .react-grid-Viewport": {
      marginTop: "-35px",
    },
    ".mobile &.card-grid *": {
      backgroundColor: "transparent",
    },
    "&.card-grid .toolbar": {
      display: "none",
      boxShadow: "none",
      top: "0",
      padding: "0",
      margin: "0 0 16px 0",
      height: "40px",
    },
    "&.card-grid .toolbar .filters": {
      display: "none",
    },
    "&.card-grid .toolbar .create-button": {
      backgroundColor: theme.palette.secondary.main,
      color: "#ffffff",
    },
    "&.card-grid .toolbar .create-button.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
    },
    ".mobile &.card-grid .react-grid-Row .row-selected": {
      background: "transparent",
    },
    ".mobile &.card-grid .react-grid-Cell": {
      padding: 0,
    },
    "&.card-grid .datagrid-paper": {
      padding: 16,
      marginBottom: theme.spacing(),
      marginTop: theme.spacing(),
    },
    ".mobile &.card-grid .datagrid-paper": {
      padding: "0 8px",
      boxShadow: "none",
      backgroundColor: "transparent",
      marginTop: 0,
    },
    "&.card-grid .react-grid-wrapper": {},
    "&.card-grid .react-grid-Canvas": {},
    "& .react-grid-Cell": {
      backgroundColor: "transparent",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ".mobile &": {
      left: "0",
      width: "100%",
      bottom: "17px",
      zIndex: "500",
      position: "fixed",
      alignItems: "stretch",
      justifyContent: "space-between",
      display: "flex",
      padding: "0 15px",
    },
  },
  mobileCard: {},
  mobileCardTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mobileCardId: {
    fontSize: "12px",
    color: "gray",
  },
  mobileCardSubject: {
    fontSize: 18,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "calc(100% - 30px)",
  },
  mobileCardStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  mobileCardStatusDot: {
    width: 15,
    height: 15,
    borderRadius: "100%",
  },
  mobileCardBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mobileCardType: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "calc(100% - 60px)",
  },
  mobileCardResponsible: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "12px",
    color: "gray",
  },
  mobileCardDate: {},
  crudWrapper: {
    minWidth: 1100,
    ".mobile &": {
      minWidth: "auto",
    },
  },
  tabs: {
    minHeight: "auto",
    height: 35,
    display: "inline-block",
    ".mobile &": {
      display: "flex",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tab: {
    minHeight: "auto",
    height: 35,
    border: "1px solid " + theme.palette.secondary.main,
    borderRadius: "0 5px 5px 0",
    textTransform: "none",
    padding: 0,
    color: theme.palette.secondary.main,
    cursor: "pointer",
    ".mobile &": {
      width: "50%",
      maxWidth: "50%",
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "#ffffff",
    },
    "&:first-child": {
      borderRadius: "5px 0 0 5px",
    },
  },
});

class Tickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createFormRef: null,
      allRows: [],
      isFirstLoad: true,
    };
  }
  componentDidMount() {
    if (Object.keys(this.props.currentEvent) !== 0 && this.props.currentEvent.obj_id) {
      this.props.init(this.props.currentEvent.obj_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentEvent !== this.props.currentEvent || Object.keys(this.props.currentEvent) === 0) {
      this.props.init(this.props.currentEvent.obj_id);
    }
    const { openTicket, map } = this.props;
    if (!openTicket || !map.tickets.length) return;
  
    const params = new URLSearchParams(window.location.search);
    const ticketId = params.get("ticket");
    
    if (ticketId) {
      const foundTicket = map.tickets.find(ticket => ticket["service_desc-obj_id"] === Number(ticketId));
      
      if (foundTicket) {
        openTicket(ticketId);
      }
    }
  }

  processRows = async (rowsRaw, rows) => {
    const { classes, filters } = this.props;
    let processedRows = rows || [];

    if (this.state.isFirstLoad) {
      this.setState({
        allRows: processedRows, 
        isFirstLoad: false,
      });
    }
    if (!!filters.date.min || !!filters.date.max) {
      processedRows = processedRows.filter((row) => {
        let date = moment(row["service_desc-created"], "YYYY-MM-DD HH:mm:ss.SSSSSS");
        let matchMin = !!filters.date.min ? date.isSameOrAfter(moment(filters.date.min, "DD.MM.YYYY HH:mm"), "minute") : true;
        let matchMax = !!filters.date.max ? date.isSameOrBefore(moment(filters.date.max, "DD.MM.YYYY HH:mm"), "minute") : true;
        return matchMin && matchMax;
      });
    }
      if (!!filters.incidentType) {
        processedRows = processedRows.filter((row) => {
          return row["service_desc-incident_type"] === filters.incidentType
        });
      }
      if (!!filters.status) {
        processedRows = processedRows.filter((row) => {
          return row["service_desc-status"] === filters.status
        });
      }
      if (!!filters.incidentLevel) {
        processedRows = processedRows.filter((row) => {
          return row["service_desc-incident_level"] === filters.incidentLevel
        });
    }

    processedRows.forEach((row) => {
      row["mobile_card"] = (
        <div className={classes.mobileCard}>
          <div className={classes.mobileCardTop}>
            <Typography
              component="h3"
              className={classes.mobileCardId}
              style={{
                color: TICKET_COLORS[row["service_desc-status_code"]],
              }}>
              id {row["service_desc-obj_id"]}
            </Typography>
            <Typography
              component="h3"
              className={classes.mobileCardSubject}>
              <strong>{row["service_desc-subject"]}</strong>
            </Typography>
            <Box className={classes.mobileCardStatus}>
              <Typography
                style={{
                  color: TICKET_COLORS[row["service_desc-status_code"]],
                }}>
                {row["service_desc-status"]}
              </Typography>
              <div
                className={classes.mobileCardStatusDot}
                style={{
                  backgroundColor: TICKET_COLORS[row["service_desc-status_code"]],
                }}>
                &nbsp;
              </div>
            </Box>
          </div>
          <div className={classes.mobileCardBottom}>
            {/*<Typography color="textSecondary" gutterBottom>*/}
            {/*{row['service_desc-assigned']}*/}
            {/*</Typography>*/}
            <Typography
              color="textSecondary"
              component="p"
              className={classes.mobileCardType}>
              {row["service_desc-incident_type"]}, {row["service_desc-incident_level"]}
            </Typography>
            <Typography
              color="textSecondary"
              component="p"
              className={classes.mobileCardDate}>
              {moment(row["service_desc-created"]).format("DD.MM HH:mm:ss")}
            </Typography>
          </div>
          <Typography className={classes.mobileCardResponsible}>{row["service_desc-responsible_ref"]}</Typography>
        </div>
      );
    });
    return processedRows;
  };

  rowPropsGetter = (idx, data) => {
    return {
      style: {
        backgroundColor: TICKET_COLORS[data["service_desc-status_code"]],
      },
    };
  };

  handleCheckbox = () => {
    const IsDirector = this.props.roles.some((role) => role === "service_desc_director");
    if (IsDirector) {
      const { createFormRef } = this.state;
      const IncidentTypeValue = createFormRef?.values["service_desc-incident_type"];
      if (createFormRef) {
        if (!!IncidentTypeValue) {
          const options = createFormRef?.fieldRefs["service_desc-incident_type"]?.getOptions();
          if (createFormRef?.values["service_desc-is_important"] !== true) {
            if (options?.find((item) => item.value === IncidentTypeValue && !!item.is_important)) {
              createFormRef.setFieldValue("service_desc-is_important", true, true);
              createFormRef.fieldRefs["service_desc-incident_type"].disabled = true;
            }
          }
          // if (createFormRef?.values["service_desc-is_important"] === true) {
          //   if (options?.find((item) => item.value === IncidentTypeValue && !item.is_important)) {
          //     createFormRef.setFieldValue("service_desc-is_important", false, true);
          //     createFormRef.fieldRefs["service_desc-incident_type"].disabled = false;
          //   }
          // }
        }
      }
    } else return;
  };

  getVariants = (tikets) => {
    if (!Array.isArray(tikets)) {
        return {
            incidentTypes: [],
            statuses: [],
            incidentLevels: [],
        };
    }

    const incidentTypesSet = new Set();
    const statusesSet = new Set();
    const incidentLevelsSet = new Set();

   tikets.forEach((ticket) => {
            if (ticket['service_desc-incident_type']) {
                incidentTypesSet.add(ticket['service_desc-incident_type']);
            }
            if (ticket['service_desc-status']) {
                statusesSet.add(ticket['service_desc-status']);
            }
            if (ticket['service_desc-incident_level']) {
                incidentLevelsSet.add(ticket['service_desc-incident_level']);
            }
    });

    const incidentTypes = Array.from(incidentTypesSet);
    const statuses = Array.from(statusesSet);
    const incidentLevels = Array.from(incidentLevelsSet);

    return {
        incidentTypes,
        statuses,
        incidentLevels,
    };
};

  render() {
    const {
      classes,
      restClient,
      crudConfig,
      isMobileDevice,
      inited,
      setCrudRef,
      crudRef,
      ticketId,
      openTicket,
      tab,
      setTab,
      map,
      mapSetInited,
      mapSetTickets,
      isAdmin,
      currentEvent,
      roles,
    } = this.props;

    let dataGridProps = Object.assign({}, DATAGRID_DEFAULT_PROPS);

    if (isMobileDevice) {
      dataGridProps.rowHeight = 75;
      dataGridProps.onRowClick = (rowNum, data) => (!!data && !!data["service_desc-obj_id"] ? openTicket(data["service_desc-obj_id"]) : false);
    }

    dataGridProps.columns.forEach((column) => {
      if (column.hasOwnProperty("visible")) {
        return;
      }
      column.visible = column.key === "mobile_card" ? isMobileDevice : !isMobileDevice;
    });

    return (
      <React.Fragment>
        {!inited && (
          <div className={classes.progressWrapper}>
            <LinearProgress variant="query" />
          </div>
        )}
        {inited && (
          <div className={classes.root + (isMobileDevice ? " mobile" : "")}>
            <div style={{ display: "flex", gap: "20px", alignItems: "center", justifyContent: "space-between", marginBottom: "16px" }}>
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, value) => setTab(value)}
                className={classes.tabs}>
                <Tab
                  label="На схеме"
                  value={"map"}
                  className={classes.tab}
                />
                <Tab
                  label="Списком"
                  value={"list"}
                  className={classes.tab}
                />
              </Tabs>
              {isAdmin && map.tickets.length !== 0 && <ExportButton exportPath={`/service_desc/ticket/export?project_id=${currentEvent.obj_id}`} />}
            </div>
            <div className={classes.toolbar}>
              <CreateButton />
              <Filters type={"tickets"} {...this.getVariants(this.state.allRows)}/>
            </div>
            <div style={{ display: tab === "map" ? "block" : "none" }}>
              <Map />
              <img
                src={`${restClient.host}service_desc/file-storage/img?token=${restClient.authToken}&guid=${currentEvent.map_event}`}
                onLoad={() => {
                  if (!map.inited && Object.keys(currentEvent) !== 0) {
                    mapSetInited(true);
                  }
                }}
                style={{ display: "none" }}
              />
            </div>
            <div
              className={classes.crudWrapper}
              style={{ display: tab === "list" ? "block" : "none" }}>
              <Crud
                restClient={restClient}
                config={crudConfig}
                ref={(crud) => {
                  if (!!crud && !crudRef) {
                    setCrudRef(crud);
                  }
                }}
                dataGridProps={{
                  ...dataGridProps,
                  offsetTop: isMobileDevice ? 130 : 233,
                  filters: !isMobileDevice,
                  getRows: getRows,
                  classes: { root: classes.gridRoot },
                  onRowDoubleClick: (idx, data) => (!!data && !!data["service_desc-obj_id"] ? openTicket(data["service_desc-obj_id"]) : false),
                  processRows: this.processRows,
                  enableCellCopy: true,
                  afterLoadRows: (rows) => {
                    mapSetTickets(rows);
                  },
                }}
                simpleFormProps={{
                  setSimpleFormRef: (ref) => {
                    if (ref) {
                      this.setState({ createFormRef: ref });
                    }
                  },
                  onValidate: this.handleCheckbox,
                }}
              />
            </div>
          </div>
        )}
        {!!ticketId && <Ticket />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restClient: state.page.elkUser.restClient,
    crudRef: state.tickets.crudRef,
    ticketId: state.tickets.ticketId,
    inited: state.tickets.inited,
    crudConfig: state.tickets.crudConfig,
    isMobileDevice: state.page.isMobileDevice,
    filters: state.tickets.filters,
    tab: state.tickets.tab,
    map: state.tickets.map,
    isAdmin: state.page.isAdmin,
    currentEvent: state.events.currentEvent,
    roles: state.profile.profileData?.roles || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: (project_id) => dispatch(ticketsInit(project_id)),
    setCrudRef: (crudRef) => dispatch(ticketsSetCrudRef(crudRef)),
    openTicket: (id) => dispatch(ticketsOpenTicket(id)),
    setTab: (tab) => dispatch(ticketsSetTab(tab)),
    mapSetInited: (inited) => dispatch(ticketsMapSetInited(inited)),
    mapSetTickets: (tickets) => dispatch(ticketsMapSetTickets(tickets)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Tickets));
