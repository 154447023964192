import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import { connect, useDispatch } from 'react-redux'
import StatusSelect from './StatusSelect'
import { ticketsCloseTicket } from '../../actions/tickets'
import CancelDialog from './CancelDialog'
import { ticketAddComment, ticketSave } from '../../actions/ticket'
import ReasonDialog from './ReasonDialog'
import { Box, Button, Typography } from '@material-ui/core'

const photoConfig = {
  label: 'Фото',
  uploadUrl: '/service_desc/file-storage/set-images?project=SERVICEDESC',
  downloadUrl: 'service_desc/file-storage/img-from-file?project=SERVICEDESC',
  multiple: true,
  type: 'image',
  maxFiles: 5,
  maxSize: 5 * 1024 * 1024,
}

const styles = (theme) => ({
  ticketButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
  },
  button: {
    display: 'flex',
    gap: theme.spacing(),
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorText: {
    fontSize: '12px',
    color: 'rgb(192, 0, 0)',
  },
})

const TicketButtons = ({
  IsDirector,
  IsDirectorate,
  IsDircontractor,
  IsContractor,
  IsSubcontractor,
  isExecutor,
  classes,
  isAdmin,
  isResponsinble,
  status,
  client,
  projectId,
  ticketId,
  closeTicket,
  save,
  editDisabled,
  formRef,
  updateTickets,
  isPhotoRequired
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [isDoneDialogOpen, setIsDoneDialogOpen] = React.useState(false)
  const [files, setFiles] = React.useState([
  ])
  const dispatch = useDispatch()

  const getReason = () => {
    if (isExecutor) {
        return 'того, почему инцидент не решен'
    }
    if (IsDircontractor || IsContractor || IsSubcontractor) {
      return 'того, почему инцидент необходимо вернуть на доработку'
    }
  }

  const handleChangeStatus = async (reason, status, photos) => {
    let sendStatus = {}
    sendStatus.status_code = status
    try {
      await client.post(`/service_desc/ticket/define-status/?ticket_id=${ticketId}&project_id=${projectId}`, sendStatus)
      if (reason) {
        const tag = 'report'
        dispatch(ticketAddComment(reason, tag, photos))
      }
      updateTickets()
      closeTicket()
    } catch (e) {
      console.log('Ошибка отказа', e)
    }
  }
  const isExecutorAndStatus = isExecutor && (status.value === '1569911' || status.value === '1570000')
  const shouldRenderCloseButton =
    (IsDirector || IsDirectorate) && (status.value === '1569912' || status.value === '1569999')

    const handleCloseDialog = () => {
      setIsDialogOpen(false)
      setIsDoneDialogOpen(false)
      setFiles([])
    }
  
    const handleFileChange = (res, name, newFiles) => {
      if (res) {
          if (!newFiles?.length) {
              setFiles([...res]);
          } else {
              setFiles([...new Set([...files, ...res])]);
          }
      } else {
          alert('Ошибка загрузки файлов');
      }
  };
  

  return (
    <div className={classes.ticketButtons}>
      {isAdmin && (
        <Box>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={save}
            disabled={editDisabled}>
            <SaveIcon />
            Сохранить
          </Button>
          {Object.keys(formRef?.errors || {}).length > 0 && (
            <Typography className={classes.errorText}>
              *Необходимо заполнить поля:{' '}
              {formRef.props.fields
                .filter((field) => formRef.errors[field.attrs.name])
                .map((field) => field.label)
                .join(', ')}
            </Typography>
          )}
        </Box>
      )}
      {isResponsinble && isExecutorAndStatus && !isAdmin && (
        <StatusSelect
          setIsDialogOpen={() => setIsDialogOpen(true)}
          isReasonDialogOpen={() => setIsDoneDialogOpen(true)}
        />
      )}
      {shouldRenderCloseButton && (
        <Button variant="contained" color="secondary"
        onClick={() => handleChangeStatus('', 'closed')}
        >
          Закрыть
        </Button>
      )}
      <CancelDialog
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        reasonText={getReason()}
        handleSubmit={(reason, status, photos) => handleChangeStatus(reason, 'not_completed', photos)}
        restClient={client}
        isPhotoRequired={isPhotoRequired}
        photoConfig={photoConfig}
        files={files}
        handleFileChange={handleFileChange}
      />
      <ReasonDialog
        isDialogOpen={isDoneDialogOpen}
        handleCloseDialog={handleCloseDialog}
        handleSubmit={(reason, photos) => handleChangeStatus(reason, 'completed', photos)}
        photoConfig={photoConfig}
        restClient={client}
        isPhotoRequired={isPhotoRequired}
        files={files}
        handleFileChange={handleFileChange}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.page.isAdmin,
    isResponsinble: state.ticket.isResponsinble,
    status: state.ticket.status,
    client: state.page.elkUser.restClient,
    projectId: state.events.currentEvent.obj_id,
    ticketId: state.tickets.ticketId,
    editDisabled: state.ticket.editDisabled,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeTicket: () => dispatch(ticketsCloseTicket()),
    save: () => dispatch(ticketSave()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TicketButtons))
