import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import DefaultDialog from '@ksbteam/core/components/Dialog/DefaultDialog'
import {
  ticketsCloseTicket,
  ticketsMapSetHighLighted,
  ticketsMapChangeScale,
  ticketsSetTab,
} from '../../actions/tickets'
import { ticketSetFormRef, ticketSetFormTempValues, ticketInit, ticketSetEditDisabled } from '../../actions/ticket'
import { connect } from 'react-redux'
import SimpleForm from '@ksbteam/core/components/Form/SimpleForm'
import Comments from './Comments'
import Typography from '@material-ui/core/Typography'
import CommentForm from './Comments/CommentForm'
import CircularProgress from '@material-ui/core/CircularProgress'
import TicketButtons from './TicketButtons'
import MapIcon from '@material-ui/icons/Map'
import History from './History'
import Button from '@material-ui/core/Button'
import Assign from './Assign'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const styles = (theme) => ({
  progressWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editFormWrapper: {
    marginBottom: theme.spacing(2),
    '&.disabled .MuiFormLabel-root.Mui-disabled': {
      color: '#5a5a5a',
    },
    '&.disabled .MuiInputBase-root.Mui-disabled': {
      color: '#5a5a5a',
      cursor: 'not-allowed',
    },
  },
  showMapButton: {
    textTransform: 'none',
    padding: 0,
  },
})

class Ticket extends React.Component {
  constructor() {
    super()
    this.state = {
      asignDisabled: false,
    }
  }
  componentDidMount() {
    this.props.init()
    this.scrollToLastInspection
  }

  componentDidUpdate(prevProps) {
    const { roles, status } = this.props
    const isExecutor = roles.some((role) => role === 'service_desc_executor')


    if (prevProps.isExecutor !== isExecutor || prevProps.status?.value !== status?.value) {
      setTimeout(this.scrollToLastInspection, 0);
    }

    const { formRef, setFormTempValues, formTempValues } = this.props
    if (!!formRef && !formTempValues) {
      setFormTempValues(formRef.getPreparedValues())
    }
  }

  scrollToLastInspection = () => {
    const { roles, status } = this.props
    const isExecutor = roles.some((role) => role === 'service_desc_executor')

    if (isExecutor && status?.value === '1569911') {
      const lastInspectionElement = document.querySelector(('[class*="lastInspection"]'))

      if (lastInspectionElement) {
        lastInspectionElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  handleButtonDisable = () => {
    const { formRef, editDisabled, setDisabled } = this.props

    const isValid = formRef?.isValid
    if (isValid !== undefined && !isValid !== editDisabled) {
      setDisabled(!isValid)
    }

    const hasIncidentLevel = !formRef?.values['service_desc-incident_level']
    if (hasIncidentLevel !== this.state.asignDisabled) {
      this.setState({ asignDisabled: hasIncidentLevel })
    }
  }

  showMapClick = () => {
    const { closeTicket, mapSetHighLighted, mapChangeScale, ticketId, setTab } = this.props
    mapSetHighLighted([ticketId])
    mapChangeScale(0.65)
    setTab('map')
    closeTicket()
  }

  render() {
    const {
      classes,
      open,
      ticketId,
      status,
      inited,
      editMode,
      formConfig,
      formRef,
      restClient,
      closeTicket,
      setFormRef,
      roles,
      isResponsinble,
      isPhotoRequired,
      projectId,
      editDisabled,
      isTiketHasDircontractor,
      updateTickets,
    } = this.props
    const statusText = !!status && status.title ? `(${status.title})` : ''
    const titleDefaultText = `Инцидент ${ticketId} ${statusText}`
    const hasRoles = !!roles.length
    const IsDirector = roles.some((role) => role === 'service_desc_director')
    const IsDirectorate = roles.some((role) => role === 'service_desc_directorate')
    const IsDircontractor = roles.some((role) => role === 'service_desc_manager_company')
    const IsContractor = roles.some((role) => role === 'service_desc_contractor')
    const IsSubcontractor = roles.some((role) => role === 'service_desc_subcontractor')
    const isExecutor = roles.some((role) => role === 'service_desc_executor')
    const isCallcenter = roles.some((role) => role === 'service_desc_callcenter')

    let title = (
      <React.Fragment>
        {titleDefaultText}
        <div>
          <Button className={classes.showMapButton} onClick={this.showMapClick}>
            <MapIcon /> {'Показать на схеме'}
          </Button>
        </div>
      </React.Fragment>
    )
    return (
      <DefaultDialog open={open} fullScreen={true} onClose={closeTicket} title={title}>
        {!inited && (
          <div className={classes.progressWrapper}>
            <CircularProgress size={40} />
          </div>
        )}
        {inited && (
          <React.Fragment>
            <div className={classes.editFormWrapper + (!editDisabled ? ' disabled' : '')}>
              <TicketButtons
                hasRoles={hasRoles}
                IsDirector={IsDirector}
                IsDirectorate={IsDirectorate}
                IsDircontractor={IsDircontractor}
                IsContractor={IsContractor}
                IsSubcontractor={IsSubcontractor}
                isExecutor={isExecutor}
                formRef={this.props?.formRef}
                updateTickets={updateTickets}
                isPhotoRequired={isPhotoRequired}
              />
              <SimpleForm
                ref={(simpleForm) => {
                  if (!formRef && !!simpleForm) {
                    setFormRef(simpleForm)
                  }
                }}
                {...formConfig}
                inited={true}
                restClient={restClient}
                hideSubmitButton={true}
                initValues={formConfig.values}
                onValidate={this.handleButtonDisable}
              />
            </div>
            {!isExecutor && !IsDirector && !IsDirectorate && (
              <Assign
                restClient={this.props.restClient}
                tiketID={this.props.ticketId}
                closeTicket={closeTicket}
                projectId={projectId}
                IsDircontractor={IsDircontractor}
                IsContractor={IsContractor}
                IsSubcontractor={IsSubcontractor}
                status={status}
                editDisabled={this.state.asignDisabled}
                isTiketHasDircontractor={isTiketHasDircontractor}
                isResponsinble={isResponsinble}
                isPhotoRequired={isPhotoRequired}
                isCallcenter={isCallcenter}
                updateTickets={updateTickets}
              />
            )}
            {!editMode && (
              <React.Fragment>
                <Typography variant="h2" component="h2">
                  {'Комментарии'}
                </Typography>
                <Comments />
                <CommentForm />
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h2" component="h2">
                      История изменений
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <History />
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </DefaultDialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    open: state.tickets.ticketIsOpen,
    ticketId: state.tickets.ticketId,
    inited: state.ticket.inited,
    formConfig: state.ticket.formConfig,
    formRef: state.ticket.formRef,
    formTempValues: state.ticket.formTempValues,
    restClient: state.page.elkUser.restClient,
    editMode: state.ticket.editMode,
    status: state.ticket.status,
    editDisabled: state.ticket.editDisabled,
    roles: state.profile.profileData?.roles || [],
    isResponsinble: state.ticket.isResponsinble,
    isPhotoRequired: state.ticket.isPhotoRequired,
    projectId: state.events.currentEvent.obj_id,
    isTiketHasDircontractor: !!(
      state?.tickets?.map?.tickets?.find(
        (t) => t['service_desc-obj_id'] === state?.tickets?.ticketId
      )?.['service_desc-dircontractor']),
    updateTickets: state.tickets?.crudRef?.dataGrid?.updateRows,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeTicket: () => dispatch(ticketsCloseTicket()),
    init: () => dispatch(ticketInit()),
    setFormRef: (formRef) => dispatch(ticketSetFormRef(formRef)),
    mapSetHighLighted: (highLighted) => dispatch(ticketsMapSetHighLighted(highLighted)),
    mapChangeScale: (scale) => dispatch(ticketsMapChangeScale(scale)),
    setTab: (tab) => dispatch(ticketsSetTab(tab)),
    setFormTempValues: (values) => dispatch(ticketSetFormTempValues(values)),
    setDisabled: (bool) => dispatch(ticketSetEditDisabled(bool)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Ticket))
