import { Box, Button, MenuItem, Select, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { getName } from '../helpers/helpers'
import SuccesMessage from '../SuccesMessage'
import ErrorMessage from '../ErrorMessage'
import CancelDialog from './CancelDialog'
import { useDispatch } from 'react-redux'
import { ticketAddComment, ticketSave } from '../../actions/ticket'
import SimpleForm from '@ksbteam/core/components/Form/SimpleForm'

const useStyles = makeStyles((theme) => ({
  appointWrapper: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  appointContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  appointSelect: {
    minWidth: '30%',
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  agreeButton: {
    background: 'green',
    color: 'white',
    '&:hover': {
      background: 'green',
    },
  },
  errorText: {
    fontSize: '12px',
    color: 'rgb(192, 0, 0)',
  },
}))

function Assign({
  restClient,
  tiketID,
  closeTicket,
  projectId,
  IsDircontractor,
  IsContractor,
  IsSubcontractor,
  status,
  editDisabled,
  isTiketHasDircontractor,
  isResponsinble,
  isPhotoRequired,
  isCallcenter,
  updateTickets,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [assignConfig, setAssignConfig] = React.useState({})
  const [assignRef, setAssignRef] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [successMessage, setSuccessMessage] = React.useState('')
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)

  const shouldNotRenderCloseButton =
    (IsDircontractor && status.value === '1569910') ||
    (IsContractor &&
      (status.value === '1569911' ||
        ((status.value === '1569912' || status.value === '1569999') && isTiketHasDircontractor))) ||
        IsSubcontractor
        
  const shouldRenderReviewButton =
    (status.value === '1569912' || status.value === '1569999') && (IsContractor || IsSubcontractor) && isResponsinble
  const shouldShowDialog =
    (status.value === '1569912' || status.value === '1569999') &&
    (IsDircontractor || IsContractor || IsSubcontractor || isCallcenter)

  const shouldShowButtons = IsDircontractor || (IsContractor || IsSubcontractor ? isResponsinble : true)

  const handleClick = async () => {
    if (restClient && tiketID) {
      if (shouldShowDialog && !isDialogOpen) {
        setIsDialogOpen(true)
        return
      }
      try {
        assignRef?.formik?.submitForm()
        const intervalId = setInterval(() => {
          if (!assignRef?.formik?.state?.isSubmitting) {
            if(!assignRef?.state?.saveErrors?.length) {
              closeTicket();
            }
            clearInterval(intervalId)
          }
        }, 500)
      } catch (error) {
        console.error('Error fetching assign type', error)
        setErrorMessage('Ошибка назначения ответственного, назначьте еще раз или попробуйте позже')
      }
    }
  }

  const handleChangeStatus = async (reason, status) => {
    let sendStatus = {}
    sendStatus.status_code = status
    try {
      if (!!status) {
        await restClient.post(
          `/service_desc/ticket/define-status/?ticket_id=${tiketID}&project_id=${projectId}`,
          sendStatus
        )
      }
      updateTickets()
      if (isDialogOpen) {
        await handleClick()
      }
      if (reason) {
        dispatch(ticketAddComment(reason, 'rework'))
      }
    } catch (e) {
      console.log('Ошибка отказа', e)
    } finally {
      isDialogOpen ? setIsDialogOpen(false) : ''
      closeTicket()
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      if (restClient && tiketID) {
        try {
          const result = await restClient.get(`/service_desc/ticket/responsible-list/?ticket_id=${tiketID}`)
          setAssignConfig(result)
        } catch (error) {
          console.error('Error fetching responsible config', error)
        }
      }
    }

    fetchData()
  }, [restClient, tiketID])

  const hasValidValue = React.useCallback(() => {
    return Object.values(assignConfig?.values)?.some((value) => value != null) ?? false
  }, [assignConfig?.values])

  return (
    !!Object.keys(assignConfig).length &&
    hasValidValue &&
    shouldShowButtons && (
      <Box className={classes.appointWrapper}>
        <Typography variant="h6">Назначить ответственного</Typography>
        <SimpleForm
          ref={(simpleForm) => {
            if (!assignRef && !!simpleForm) {
              setAssignRef(simpleForm)
            }
          }}
          {...assignConfig}
          inited={true}
          restClient={restClient}
          hideSubmitButton={true}
          initValues={assignConfig.values}
        />
        <Box className={classes.buttons}>
          <Button variant="contained" disabled={editDisabled} onClick={handleClick} className={classes.agreeButton}>
            Назначить
          </Button>
          {shouldRenderReviewButton && (
            <Button variant="contained" color="secondary" onClick={() => handleChangeStatus('', 'checked')}>
              Проверено
            </Button>
          )}
          {!shouldNotRenderCloseButton && (
            <Button variant="contained" color="secondary" onClick={() => handleChangeStatus('', 'closed')}>
              Закрыть
            </Button>
          )}
        </Box>
        <SuccesMessage isOpen={!!successMessage} onClose={() => setSuccessMessage('')} text={successMessage} />
        <ErrorMessage isOpen={!!errorMessage} onClose={() => setErrorMessage('')} text={errorMessage} />
        <CancelDialog
          isDialogOpen={isDialogOpen}
          handleCloseDialog={() => setIsDialogOpen(false)}
          reasonText="того, почему инцидент необходимо вернуть на доработку"
          handleSubmit={handleChangeStatus}
          status='assigned'
        />
        {editDisabled && (
          <Typography className={classes.errorText}>*Необходимо заполнить поля: Уровень инцидента</Typography>
        )}
      </Box>
    )
  )
}

export default Assign
