const initialState = {
  ticketIsOpen: false,
  ticketId: null,
  crudConfig: null,
  inited: false,
  crudRef: null,
  filters: {
    date: {
      min: null,
      max: null,
    },
    incidentType: null,
    status: null,
    incidentLevel: null
  },
  filtersDialog: { open: false },
  tab: localStorage.getItem("tab") || "map",
  map: {
    inited: false,
    tickets: [],
    highLighted: [],
    ref: null,
    createCoords: [0, 0],
    showCreateButton: false,
    createButtonCoords: [0, 0],
  },
};

export function tickets(state = Object.assign({}, initialState), action) {
  switch (action.type) {
    case "TICKETS_SET_INITIAL_STATE":
      return Object.assign({}, initialState);
    case "TICKETS_SET_INITED":
      return Object.assign({}, state, {
        inited: action.inited,
      });
    case "TICKETS_SET_CRUD_CONFIG":
      return Object.assign({}, state, {
        crudConfig: action.crudConfig,
      });
    case "TICKETS_SET_CRUD_REF":
      return Object.assign({}, state, {
        crudRef: action.crudRef,
      });
    case "TICKETS_SET_TICKET_IS_OPEN":
      return Object.assign({}, state, {
        ticketIsOpen: action.ticketIsOpen,
        ticketId: action.ticketId,
      });
    case "TICKETS_OPEN_TICKET":
      return Object.assign({}, state, {
        ticketIsOpen: true,
        ticketId: action.ticketId,
      });
    case "TICKETS_CLOSE_TICKET":
      return Object.assign({}, state, {
        ticketIsOpen: false,
        ticketId: null,
      });
    case "TICKETS_SET_FILTERS":
      return Object.assign({}, state, {
        filters: action.filters,
      });
    case "TICKETS_CLEAR_FILTERS":
      return Object.assign({}, state, {
        filters: {
          date: {
            min: null,
            max: null,
          },
        incidentType: null,
        status: null,
        incidentLevel: null
        },
      });
    case "TICKETS_OPEN_FILTERS_DIALOG":
      return Object.assign({}, state, {
        filtersDialog: { open: true },
      });
    case "TICKETS_CLOSE_FILTERS_DIALOG":
      return Object.assign({}, state, {
        filtersDialog: { open: false },
      });
    case "TICKETS_SET_TAB":
      localStorage.setItem("tab", action.tab);
      return Object.assign({}, state, {
        tab: action.tab,
      });
    case "TICKETS_MAP_SET_INITED":
      return Object.assign({}, state, {
        map: Object.assign({}, state.map, {
          inited: action.inited,
        }),
      });
    case "TICKETS_MAP_SET_TICKETS":
      return Object.assign({}, state, {
        map: Object.assign({}, state.map, {
          tickets: action.tickets,
        }),
      });
    case "TICKETS_MAP_SET_HIGHLIGHTED":
      return Object.assign({}, state, {
        map: Object.assign({}, state.map, {
          highLighted: action.highLighted,
        }),
      });
    case "TICKETS_MAP_SET_REF":
      return Object.assign({}, state, {
        map: Object.assign({}, state.map, {
          ref: action.ref,
        }),
      });
    case "TICKETS_MAP_SET_CREATE_COORDS":
      return Object.assign({}, state, {
        map: Object.assign({}, state.map, {
          createCoords: action.createCoords,
        }),
      });
    case "TICKETS_MAP_SET_SHOW_CREATE_BUTTON":
      return Object.assign({}, state, {
        map: Object.assign({}, state.map, {
          showCreateButton: action.showCreateButton,
          createButtonCoords: action.createButtonCoords,
        }),
      });
    default:
      return state;
  }
}
