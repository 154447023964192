import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Datetime from "@ksbteam/core/components/Form/Field/Datetime";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  ticketsUpdateFilters,
  ticketsClearFilters,
  ticketsApplyFilters,
  ticketsOpenFiltersDialog,
  ticketsCloseFiltersDialog,
} from "../../actions/tickets";
import {
  reportsUpdateFilters,
  reportsClearFilters,
  reportsApplyFilters,
  reportsOpenFiltersDialog,
  reportsCloseFiltersDialog,
} from "../../actions/reports";
import DefaultDialog from "@ksbteam/core/components/Dialog/DefaultDialog";
import { Box, InputLabel, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    zIndex: 50,
    justifyContent: 'space-between',
    [`@media (max-width: 1150px)`]: {
      display: "none",
    },
    "&.mobile": {
      display: "block",
      position: "relative",
      right: 0,
    },
  },
  filtersWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  dialogButtonWrapper: {
    display: "flex",
  },
  label: {
    marginRight: theme.spacing(),
    ".mobile &": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  searchButton: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    ".mobile &": {
      marginLeft: 0,
    },
  },
  dateWrapper: {
    ".mobile &": {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
    },
  },
  filtersButtonMobile: {
    textTransform: "none",
  },
  reportsButtonMobile: {
    marginBottom: theme.spacing(3),
  },
  selectContainer: {
    minWidth: "50vw",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(0, 30%))",
    gap: 8,
    justifyContent: "space-between",
    [`@media (max-width: 1150px)`]: {
      display: "none",
    },
    "&.mobile": {
      display: "flex",
      flexDirection: 'column',
      gap: theme.spacing(2)
    },
  },
  buttonsContainer: {
    minWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: 'center',
    [`@media (max-width: 1150px)`]: {
      display: "none",
    },
    "&.mobile": {
      display: "flex",
    },
  },
}));

const FiltersForm = ({ classes, filters, find, clearFilters, setFilter, isMobileDevice, incidentTypes, statuses, incidentLevels }) => (
  <div className={classes.filtersWrapper}>
    <div className={classes.root + (isMobileDevice ? " mobile" : "")}>
      <div className={classes.label}>Показать инциденты с</div>
      <div className={classes.dateWrapper}>
        <Datetime
          onChange={setFilter}
          name={"min"}
          value={filters?.date?.min}
          minDate="01-01-2020"
        />
      </div>
      <div className={classes.label}>по</div>
      <div className={classes.dateWrapper}>
        <Datetime
          onChange={setFilter}
          name={"max"}
          value={filters?.date?.max}
          needValidation={false}
          minDate="01-01-2020"
        />
      </div>
      
    </div>

    <div className={classes.selectContainer + (isMobileDevice ? " mobile" : "")}>
      <Box>
        <InputLabel shrink>Тип инцидента</InputLabel>
        <Select
          value={filters?.incidentType || ""}
          onChange={(e) => setFilter("incidentType", '', e.target.value)}
          displayEmpty
          fullWidth>
          <MenuItem
            value=""
            disabled>
            Тип инцидента
          </MenuItem>
          <MenuItem value="">Не выбрано</MenuItem>
          {incidentTypes.map((type, index) => (
            <MenuItem
              key={index}
              value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel shrink>Статус инцидента</InputLabel>
        <Select
          value={filters?.status || ""}
          onChange={(e) => setFilter("status", '',e.target.value)}
          displayEmpty
          fullWidth>
          <MenuItem
            value=""
            disabled>
            Статус
          </MenuItem>
          <MenuItem value="">Не выбрано</MenuItem>
          {statuses.map((status, index) => (
            <MenuItem
              key={index}
              value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel shrink>Уровень инцидента</InputLabel>
        <Select
          value={filters?.incidentLevel || ""}
          onChange={(e) => setFilter("incidentLevel", '',e.target.value)}
          displayEmpty
          fullWidth>
          <MenuItem
            value=""
            disabled>
            Уровень инцидента
          </MenuItem>
          <MenuItem value="">Не выбрано</MenuItem>
          {incidentLevels.map((level, index) => (
            <MenuItem
              key={index}
              value={level}>
              {level}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </div>
    <div className={classes.buttonsContainer + (isMobileDevice ? " mobile" : "")}>
        <Button
          className={classes.searchButton}
          variant={"outlined"}
          onClick={find}>
          <SearchIcon /> Искать
        </Button>
        <IconButton
          onClick={clearFilters}
          aria-label={"Очистить фильтры"}>
          <CancelIcon />
        </IconButton>
      </div>
  </div>
);

const Filters = ({ type, incidentTypes, statuses, incidentLevels }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector((state) => (type === "tickets" ? state.tickets.filters : state.reports.filters));
  const isMobileDevice = useSelector((state) => state.page.isMobileDevice);
  const filtersDialog = useSelector((state) => (type === "tickets" ? state.tickets.filtersDialog : state.reports.filtersDialog));

  const setFilter = (name, date, dateFormatted) => {
    let newFilters = { ...filters };
    if (!newFilters.date) {
      newFilters.date = {};
    }
    if (name == "min" || name == "max") {
      newFilters.date[name] = dateFormatted;
    } else newFilters[name] = dateFormatted;
    if (type === "tickets") {
      dispatch(ticketsUpdateFilters(newFilters));
    } else {
      dispatch(reportsUpdateFilters(newFilters));
    }
  };

  const find = () => {
    if (type === "tickets") {
      dispatch(ticketsApplyFilters());
    } else {
      dispatch(reportsApplyFilters());
    }
    if (isMobileDevice) {
      if (type === "tickets") {
        dispatch(ticketsCloseFiltersDialog());
      } else {
        dispatch(reportsCloseFiltersDialog());
      }
    }
  };

  const clearFilters = () => {
    if (type === "tickets") {
      dispatch(ticketsClearFilters());
    } else {
      dispatch(reportsClearFilters());
    }
  };

  const openFiltersDialog = () => {
    if (type === "tickets") {
      dispatch(ticketsOpenFiltersDialog());
    } else {
      dispatch(reportsOpenFiltersDialog());
    }
  };

  const closeFiltersDialog = () => {
    if (type === "tickets") {
      dispatch(ticketsCloseFiltersDialog());
    } else {
      dispatch(reportsCloseFiltersDialog());
    }
  };

  const filtersForm = (
    <FiltersForm
      classes={classes}
      filters={filters}
      find={find}
      clearFilters={clearFilters}
      setFilter={setFilter}
      isMobileDevice={isMobileDevice}
      incidentTypes={incidentTypes}
      statuses={statuses}
      incidentLevels={incidentLevels}
    />
  );

  return (
    <React.Fragment>
      {isMobileDevice && (
        <React.Fragment>
          <div className={classes.dialogButtonWrapper}>
            <Button
              className={`${classes.filtersButtonMobile} ${type === "reports" ? classes.reportsButtonMobile : ""}`}
              onClick={openFiltersDialog}
              variant={"contained"}
              color={"secondary"}>
              <FilterListIcon /> Фильтры
            </Button>
          </div>
          <DefaultDialog
            title={"Фильтры"}
            onClose={closeFiltersDialog}
            maxWidth={"sm"}
            fullScreen={true}
            {...filtersDialog}>
            {filtersForm}
          </DefaultDialog>
        </React.Fragment>
      )}
      {!isMobileDevice && <Box style={{ display: "flex", flexDirection: "column" }}>{filtersForm}</Box>}
    </React.Fragment>
  );
};

export default Filters;
