import React from "react";
import ElkPage from "../containers/ElkPage";
import LoginPage from "../containers/LoginPage";
import TwoStepAuthPage from "../containers/TwoStepAuthPage";
import TicketScanner from "../components/TicketScanner";
import Tickets from "../components/Tickets";
import Reports from "../components/Reports";
import { Redirect } from "react-router";
import Map from "../components/Tickets/Map";
import ElkUser from "./ElkUser";
import Profile from "../components/Profile";
import Events from "../components/Control/Events";
import Members from "../components/Control/Members";
import Roles from "../components/Control/Roles";
import Directories from "../components/Control/Directories";
import Lists from "../components/Lists";
import Companies from "../components/Control/Companies";

const TicketScannerPage = () => (
  <ElkPage>
    <TicketScanner />
  </ElkPage>
);
const TicketsPage = () => (
  <ElkPage>
    <Tickets />
  </ElkPage>
);
const ReportsPage = () => (
  <ElkPage>
    <Reports />
  </ElkPage>
);
const ProfilePage = () => (
  <ElkPage>
    <Profile />
  </ElkPage>
);
const EventsPage = () => (
  <ElkPage>
    <Events />
  </ElkPage>
);
const MembersPage = () => (
  <ElkPage>
    <Members />
  </ElkPage>
);
const CompaniesPage = () => (
  <ElkPage>
    <Companies />
  </ElkPage>
);
const RolesPage = () => (
  <ElkPage>
    <Roles />
  </ElkPage>
);
const DirectoriesPage = () => (
  <ElkPage>
    <Directories />
  </ElkPage>
);
const ListsPage = () => (
  <ElkPage>
    <Lists />
  </ElkPage>
);
class MainMenu {
  constructor() {
    this.items = [
      {
        url: `/`,
        component: () => {
          let pathnameArray = window.location.pathname.split("/");
          if (!pathnameArray[1].length) {
            return (
              <Redirect
                push
                to={`/main`}
              />
            );
          }
          return "";
        },
      },
      {
        url: `/login`,
        component: LoginPage,
      },
      {
        url: "/map",
        component: () => (
          <div>
            <Map />
          </div>
        ),
      },
      {
        url: `/two-step-auth`,
        component: TwoStepAuthPage,
      },
      {
        title: "Проверка билетов",
        url: `/skd`,
        component: TicketScannerPage,
        // areas: [`header`]
      },
      {
        title: "Инциденты",
        url: `/main`,
        component: TicketsPage,
        areas: [`header`],
      },
      {
        title: "Отчеты",
        url: `/reports`,
        component: ReportsPage,
        areas: [`header`],
      },
      {
        title: "Списки",
        url: `/lists`,
        component: ListsPage,
        areas: [`header`],
      },
      {
        title: "Профиль",
        url: `/profile`,
        component: ProfilePage,
        areas: [`header`],
      },
      {
        title: "Мероприятия",
        url: `/control/events`,
        component: EventsPage,
        type: "control",
      },
      {
        title: "Участники",
        url: `/control/members`,
        component: MembersPage,
        type: "control",
      },
      {
        title: "Организации",
        url: `/control/companies`,
        component: CompaniesPage,
        type: "control",
      },
      {
        title: "Роли",
        url: `/control/roles`,
        component: RolesPage,
        type: "control",
      },
      {
        title: "Справочники",
        url: `/control/directories`,
        component: DirectoriesPage,
        type: "control",
      },
      {
        title: "На главную",
        url: `/main`,
        component: () => <></>,
        type: "control",
      },
    ];
  }

  getRouterItems() {
    let routerItems = [];
    this.items.forEach((menuItem) => {
      let item = Object.assign({}, menuItem),
        items = [];
        if (!!item.subMenu) {
        items = items.concat(item, item.subMenu.items);
      } else {
        items.push(item);
      }
      
      routerItems = routerItems.concat(items);
    });
  
    return routerItems;
  }
  

  getHeaderItems(reportsCount, roles, isAdmin) {
    const shouldNotViewLists = ["service_desc_executor", 'service_desc_director', 'service_desc_directorate']
    const currentUrl = window.location.href;
    let menuItems = this.items.filter((item) => {
      if (currentUrl.includes("control")) {
        return item.type === "control";
      } else {
        return !!item.areas && item.areas.indexOf("header") !== -1;
      }
    });
  
    if (Number(reportsCount) === 0 || roles?.some((role) => role === "service_desc_executor")) {
      menuItems = menuItems.filter((item) => item.url !== "/reports");
    }
    if ((isAdmin) || roles.length === 0 || roles?.some((role) => shouldNotViewLists.includes(role))) {
      menuItems = menuItems.filter((item) => item.url !== "/lists");
    }
    return menuItems;
  }
  

  getHeaderIconsItems() {
    return this.items.filter((item) => !!item.areas && item.areas.indexOf("header-icons") !== -1);
  }

  getProfileItems() {
    return this.items.filter((item) => !!item.areas && item.areas.indexOf("profile") !== -1);
  }
}

export const mainMenu = new MainMenu();
