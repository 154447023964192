import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Crud from "@ksbteam/core/components/Crud";
import { rolesInit } from "../../../actions/roles";
import { getRows } from "../../helpers/helpers";

class Roles extends React.Component {
  componentDidMount() {
    if (this.props.isAdmin) {
      this.props.init();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.isAdmin && prevProps.isAdmin !== this.props.isAdmin) {
      this.props.init();
    }
  }

  render() {
    let { inited, rolesConfig, restClient } = this.props;
    return (
      <React.Fragment>
        {!inited && <LinearProgress variant="query" />}
        {inited && (
          <React.Fragment>
            {!rolesConfig ? (
              <div style={{ fontSize: "larger" }}>Недостаточно прав</div>
            ) : (
              <Crud
                restClient={restClient}
                config={rolesConfig}
                dataGridProps={{ filters: true, getRows: getRows, enableCellCopy: true }}
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inited: state.roles.inited,
    rolesConfig: state.roles.rolesConfig,
    restClient: state.page.elkUser.restClient,
    isAdmin: state.page.isAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => dispatch(rolesInit()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
