import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Crud from "@ksbteam/core/components/Crud";
import { membersInit, setOpenRoleDialog } from "../../../actions/members";
import Grid from "@material-ui/core/Grid";
import UserPassword from "../../FormFields/UserPassword";
import Field from "@ksbteam/core/components/Form/Field";
import UserRoleDialog from "../../UserRoleDialog";
import { getRows } from "../../helpers/helpers";
import ExportButton from "../../ExportButton/ExportButton";
const exportMembersPath = '/service_desc/grids/export?obj_type_code=acs_users&search='

class Members extends React.Component {
  componentDidMount() {
    if (this.props.isAdmin) {
      this.props.init();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAdmin && prevProps.isAdmin !== this.props.isAdmin) {
      this.props.init();
    }
  }

  fieldComponent(field, defaultRowProps) {
    let fieldCopy = Object.assign({}, field, {
      restClient: this.restClient,
    });

    delete fieldCopy.label;

    if (["web_login", "web_password"].includes(field.attrs.name)) {
      fieldCopy.required = true;
    }

    if (field?.attrs?.name === "web_password") {
      return (
        <div style={{ display: "flex" }}>
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
            {...defaultRowProps}>
            {field?.attrs?.type !== "hidden" && (
              <label htmlFor={field?.attrs?.name}>
                {field.label}
                {!!field.required && <sup style={{ color: "#ff0000" }}>*</sup>}
              </label>
            )}
          </Grid>
          <Grid
            item
            md={9}
            sm={9}
            xs={12}
            {...defaultRowProps}>
            <UserPassword fieldProps={fieldCopy} />
          </Grid>
        </div>
      );
    }

    return (
      <div style={{ display: "flex" }}>
        <Grid
          item
          md={3}
          sm={3}
          xs={12}
          {...defaultRowProps}>
          {field?.attrs?.type !== "hidden" && (
            <label htmlFor={field?.attrs?.name}>
              {field.label}
              {!!field.required && <sup style={{ color: "#ff0000" }}>*</sup>}
            </label>
          )}
        </Grid>
        <Grid
          item
          md={9}
          sm={9}
          xs={12}
          {...defaultRowProps}>
          <Field
            {...fieldCopy}
            useDisabledField={true}
          />
        </Grid>
      </div>
    );
  }

  render() {
    let {
      inited,
      membersConfig,
      restClient,
      setOpenRoleDialog,
      isOpenDialog,
      member,
      isMobileDevice
    } = this.props;

    return (
      <React.Fragment>
        {!inited && <LinearProgress variant="query" />}
        {inited && (
          <React.Fragment>
            {!membersConfig ? (
              <div style={{ fontSize: "larger" }}>Недостаточно прав</div>
            ) : (
              <div
                style={
                  isMobileDevice ?
                    { position: 'relative', display: "flex", flexDirection: 'column' } : { position: 'relative' }
                }
              >
                <ExportButton variant="adminBtn" isMobileDevice={isMobileDevice} exportPath={exportMembersPath}/>
                <Crud
                  restClient={restClient}
                  config={membersConfig}
                  dataGridProps={{ filters: true, getRows: getRows, enableCellCopy: true }}
                  simpleFormProps={{
                    fieldComponent: this.fieldComponent,
                  }}
                  dialogProps={{ maxWidth: "md" }}
                />
              </div>
            )}
            <UserRoleDialog
              isOpen={isOpenDialog}
              member={member}
              client={restClient}
              onClose={() => setOpenRoleDialog(false)}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inited: state.members.inited,
    membersConfig: state.members.membersConfig,
    isOpenDialog: state.members.isOpenDialog,
    isMobileDevice: state.page.isMobileDevice,
    memberDialog: state.members.member,
    restClient: state.page.elkUser.restClient,
    isAdmin: state.page.isAdmin,
    member: state.members.member,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => dispatch(membersInit()),
    setOpenRoleDialog: (state) => dispatch(setOpenRoleDialog(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Members);
