import { IconButton, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  
  filePreviewThumbnails: {
    position: 'relative',
    minWidth: '20%',
  },
  fileCaptionInfo: {
    overflow: 'hidden',
  },
  filePreviewImage: {
    width: '150px',
    height: '200px',
    objectFit: 'cover', 
  },
  filePreview: {
    '& .fileDropZone': {
      border: '2px dashed rgba(47, 47, 47, 0.2)',
      borderRadius: '1.25rem',
      transition: 'border .2s linear, height 1s linear',
    },
    '& .fileDropZoneCollapsed': {
      height: '0',
      border: 'none',
    },
    '& .fileDropZoneDragged': {
      border: '2px solid #76171E',
    },
    '& .filePreviewDesc': {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '60%',
      minHeight: '12.5rem',
      margin: '0 auto',
    },
    '& p.fileDropZoneTitle': {
      textAlign: 'center',
      fontSize: '1.6em',
      color: '#aaa',
    },
  },
  btnSm: {
    padding: '5px 10px',
    fontSize: '12px',
    lineHeight: '1.5',
    background: theme.palette.text.primary,
    color: 'white',
  },
  closeButtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 3,
  },
}))

export default useStyles

export const Thumb = ({ file, onDelete }) => {
  const classes = useStyles()

  return (
    
      <div className={classes.filePreviewThumbnails}>
        {file.file.type?.startsWith('image/') ? (
          <img
            src={file.preview}
            alt={file.name}
            className={classes.filePreviewImage}
          />
        ) : (
          <div className={classes.filePreviewDesc}>
            <p className={classes.fileCaptionInfo}>Файл не является изображением</p>
          </div>
        )}

        <div className={classes.fileThumbnailFooter}>
          <div className={classes.fileFooterCaption} title={file.name}>
            <p className={classes.fileCaptionInfo}>{file.name}</p>
          </div>
          <div className="file-actions">
            <IconButton onClick={() => onDelete(file)} className={classes.closeButtn}>
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
  )
}
