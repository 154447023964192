import { Box, Button, Dialog, DialogContent, DialogTitle, TextField, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { Thumb } from './Thumb'
import FileInput from '@ksbteam/core/components/Form/Field/FileInput'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
  agreeButton: {
    background: 'green',
    color: 'white',
    '&:hover': {
      background: 'green',
    },
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      border: '1px solid #000',
      borderRadius: theme.shape.borderRadius,
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
    '& .MuiOutlinedInput-inputMultiline': {
      padding: theme.spacing(1),
      minHeight: '100px',
    },
  },
}))

function ReasonDialog({
  isDialogOpen,
  handleCloseDialog,
  handleSubmit,
  photoConfig,
  restClient,
  isPhotoRequired,
  files,
  handleFileChange,
}) {
  const classes = useStyles()
  const [reason, setReason] = useState('')

  const handleTextChange = (event) => {
    setReason(event.target.value)
  }

  const handleAgreeClick = async () => {
    await handleSubmit(reason, files)
    handleCloseDialog()
    setReason('')
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => {
        handleCloseDialog()
        setReason('')
      }}>
      <DialogTitle>Пожалуйста, напишите отчет о решении данного инцидента</DialogTitle>
      <DialogContent>
        <form className={classes.form}>
          <TextField
            multiline={true}
            rows={3}
            value={reason}
            placeholder="Текст отчета"
            onChange={handleTextChange}
            variant="outlined"
            className={classes.textField}
          />
          <Box title="Загрузите не более 5 файлов по 5 мегабайт каждый">
            <FileInput
              {...photoConfig}
              restClient={restClient}
              required={Number(isPhotoRequired)}
              onChange={handleFileChange}
              label={`Фото ${isPhotoRequired ? '* обязательное поле' : ''}`}
              value={files}
            />
          </Box>
          <Box className={classes.buttons}>
            <Button
              variant="contained"
              className={classes.agreeButton}
              onClick={handleAgreeClick}
              disabled={!reason.trim() || reason.length < 3}>
              Отправить
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleCloseDialog()
                setReason('')
              }}>
              Закрыть
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ReasonDialog
