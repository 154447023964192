import React from "react";
import { Box, CircularProgress, Typography, makeStyles } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import Crud from "@ksbteam/core/components/Crud";
import { useDispatch, useSelector } from "react-redux";
import { getName, getRows } from "../helpers/helpers";
import { getCurrentList, getListsConfig, setErrorMessage, setSuccessMessage } from "../../actions/lists";
import SuccesMessage from "../SuccesMessage";
import ErrorMessage from "../ErrorMessage";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "calc(100vh - 108px)",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  rootTree: {
    maxHeight: "100%",
    height: "calc(100vh - 110px)",
    flexGrow: 1,
    minWidth: "25%",
    maxWidth: 276,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid rgba(0,0,0,0.2)",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    margin: "8px 0",
    "&:first-child": {
      marginTop: "16px",
    },
  },
  loadingBox: {
    height: "85svh",
    width: "100%",
    display: "flex",
    alignItems: 'center', 
    justifyContent: 'center'
  },
}));

function Lists() {
  const [currentUrl, setCurrentUrl] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();
  const avaibleList = useSelector((state) => state.lists.avaibleList);
  const projectId = useSelector((state) => state.events.currentEvent.obj_id);
  const currentList = useSelector((state) => state.lists.currentList);
  const restClient = useSelector((state) => state.page.elkUser.restClient);
  const successMessage = useSelector((state) => state.lists.successMessage);
  const errorMessage = useSelector((state) => state.lists.errorMessage);
  const hideColumns = [
    { key: "acs_org-obj_id", visible: false },
    { key: "acs_org-has_subcontractor", visible: false },
    { key: "acs_org-service_event_id", visible: false },
    { key: "acs_org-resp_type", visible: false },
  ];

  const handleNodeSelect = (event, node) => {
    setCurrentUrl(Object.values(avaibleList.find((item) => Object.keys(item)[0] === node))[0]);
  };

  React.useEffect(() => {
    try {
      if (currentUrl) {
        dispatch(getCurrentList(currentUrl));
      }
    } catch (e) {
      console.log("Ошибка при загрузке спиков,", е);
    } finally {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [currentUrl, dispatch]);

  React.useEffect(() => {
    if (projectId) {
      dispatch(getListsConfig());
    }
  }, [projectId]);

  return isLoading ? (
    <Box className={classes.loadingBox}>
      <CircularProgress size='60px'/>
    </Box>
  ) : (
    <React.Fragment>
      {avaibleList.length === 0 && !isLoading ? (
        <div style={{ fontSize: "larger" }}>Недостаточно прав</div>
      ) : (
        <Box className={classes.wrapper}>
          <div className={classes.rootTree}>
            <TreeView onNodeSelect={handleNodeSelect}>
              {avaibleList.map((el) => (
                <TreeItem
                  key={Object.values(el)[0]}
                  nodeId={Object.keys(el)[0]}
                  label={
                    <div className={classes.labelRoot}>
                      <Typography
                        variant="body2"
                        className={classes.labelText}>
                        {getName(Object.keys(el)[0])}
                      </Typography>
                    </div>
                  }></TreeItem>
              ))}
            </TreeView>
          </div>
          {currentList && Object.keys(currentList)?.length !== 0 && (
            <Crud
              key={currentUrl + currentList?.code}
              restClient={restClient}
              config={currentList}
              dataGridProps={{ filters: true, getRows: getRows, columns: hideColumns, enableCellCopy: true }}
            />
          )}
          <SuccesMessage
            isOpen={!!successMessage}
            onClose={() => dispatch(setSuccessMessage(""))}
            text={successMessage}
          />
          <ErrorMessage
            isOpen={!!errorMessage}
            onClose={() => dispatch(setErrorMessage(""))}
            text={errorMessage}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

export default Lists;
