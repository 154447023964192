import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Crud from "@ksbteam/core/components/Crud";
import { directoriesSetInited, getDirectoriesConfig } from "../../../actions/directories";
import { TreeItem, TreeView } from "@material-ui/lab";
import { Box, Typography, withStyles } from "@material-ui/core";
import { getRows } from "../../helpers/helpers";

const styles = (theme) => ({
  wrapper: {
    minHeight: "calc(100vh - 108px)",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  rootTree: {
    maxHeight: "100%",
    height: "calc(100vh - 110px)",
    flexGrow: 1,
    minWidth: "25%",
    maxWidth: 276,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid rgba(0,0,0,0.2)",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    margin: "8px 0",
    "&:first-child": {
      marginTop: "16px",
    },
  },
});

class Directories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDirectorie: "",
      columnsUrl: null,
      isConfigChanged: false
    };
  }

  componentDidMount() {
    if (this.props.isAdmin) {
      this.props.init();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentDirectorie !== this.state.currentDirectorie) {
      this.props.getDirectoriesConfig(this.state.currentDirectorie);
      this.setState({ isConfigChanged: false });
    }
      if (this.props.isAdmin && prevProps.isAdmin !== this.props.isAdmin) {
        this.props.init();
      }
      if (this.props.directoriesConfig?.grid?.columns_url !== prevProps.directoriesConfig?.grid?.columns_url) {
        this.setState({ columnsUrl: this.props.directoriesConfig?.grid?.columns_url });
        this.setState({ isConfigChanged: true });
      }
  }

  componentWillUnmount(){
    this.setState({ isConfigChanged: false });
  }

  render() {
    let { restClient, classes, directoriesConfig, inited } = this.props;
    const { columnsUrl, isConfigChanged } = this.state; 

    const options = [
      { id: 1, title: "Тип инцидента", obj_type_code: "type_incident" },
      { id: 2, title: "Уровень инцидента", obj_type_code: "level_incident" },
      { id: 3, title: "Статус инцидента", obj_type_code: "status_incident" },
    ];
    
    return (
      <React.Fragment>
        {!inited && !isConfigChanged && <LinearProgress variant="query" />}
        {inited && (
          <React.Fragment>
            {!directoriesConfig ? (
              <div style={{ fontSize: "larger" }}>Недостаточно прав</div>
            ) : (
              <Box className={classes.wrapper}>
                <div className={classes.rootTree}>
                  <TreeView onNodeSelect={(event, nodeIds) => this.setState({ currentDirectorie: nodeIds })}>
                    {(options || []).map((el) => (
                      <TreeItem
                        key={el.id}
                        nodeId={el.obj_type_code}
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}>
                              {el.title}
                            </Typography>
                          </div>
                        }></TreeItem>
                    ))}
                  </TreeView>
                </div>
                {Object.keys(directoriesConfig).length !== 0 && isConfigChanged && (
                  <Crud
                    key={this.state.currentDirectorie + columnsUrl}
                    restClient={restClient}
                    config={directoriesConfig}
                    dataGridProps={{ filters: true, getRows: getRows, enableCellCopy: true }}
                  />
                )}
              </Box>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inited: state.directories.inited,
    directoriesConfig: state.directories.directoriesConfig,
    restClient: state.page.elkUser.restClient,
    isAdmin: state.page.isAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => dispatch(directoriesSetInited(true)),
    getDirectoriesConfig: (obj_type_code) => dispatch(getDirectoriesConfig(obj_type_code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Directories));
